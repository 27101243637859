import '../../webpackPublicPath';

import {getWithExpiry, setWithExpiry} from './helpers/localStorage'
import ProductShippingOptions from '../../react/ProductShippingOptions/App';

import 'globalStyles/theme.scss';

const SHIPPING = 'Nationwide Direct Ship';

const getFulfillmentCenters = async() => {
  const ttlExpiry = 1000 * 60 * 60 * 24 * 1; // 1 day
  const localStorageKey = 'fe-locations';

  let fulfillmentOptions = null;
  if(getWithExpiry(localStorageKey)) {
    fulfillmentOptions = JSON.parse(getWithExpiry(localStorageKey));
    return fulfillmentOptions
  }
  else {
    try {
      const res = await fetch('https://us-central1-cag-sandbox-a7de1700.cloudfunctions.net/shopifyLocations');
      const locations = await res.json();
      setWithExpiry(localStorageKey, JSON.stringify(locations), ttlExpiry)
      fulfillmentOptions = locations;
      return fulfillmentOptions
    }
    catch(e) {
      console.error("could not fetch users location");
      console.log(JSON.stringify(e));
    }
  }
}


const FE = (window.FE = window.FE || {});
FE.BUILDS = FE.BUILDS || {};
FE.BUILDS.Theme = FE.BUILDS.Theme || {};
FE.BUILDS.Theme.getFulfillmentCenters = getFulfillmentCenters;


FE.BUILDS.removeTaxProduct = async (productVariantID) => {
  const cart = await fetch('/cart.js')
      .then(response => response.json())
      .then(data => { return data });

  const isTaxItemAdded = cart.items.find(i => {

    return i.variant_id === productVariantID
  });

  if(!isTaxItemAdded){
    return;
  }

  await fetch(`/cart/update.js`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      updates: {
        [productVariantID]: 0,
      }
    })
  });
  window.location.reload();

}
FE.BUILDS.Theme.setupTaxationProduct = async (productVariantID, province) => {

  const cart = await fetch('/cart.js')
      .then(response => response.json())
      .then(data => { return data });

  const isTaxItemAdded = cart.items.find(i => {

    return i.variant_id === productVariantID
  });

  if(isTaxItemAdded){
    return;
  }

  await fetch(`/cart/add.js`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      items: [
        {
          quantity: 1,
          id: productVariantID,
        }
      ]
    })
  });

  if(window.location.href.includes('minnesota_tax=true')){
    return;
  }

  var newURL = window.location.href;
  if(window.location.href.includes('?')){
    newURL = newURL + '&minnesota_tax=true'
  }
  else {
    newURL = newURL + '?minnesota_tax=true'
  }
  window.location.href = newURL;

}

FE.BUILDS.Theme.ShippingLineOptions = async() => {
  let fulfillmentOptions = null;
  let shippingCenter = null;

  shippingCenter = Cookies.get('my_fulfillment_center');
  if(!shippingCenter) {
    shippingCenter = SHIPPING;
  }

  fulfillmentOptions = await getFulfillmentCenters();

  try {
    document.querySelectorAll('.fe_shipping_options').forEach(elem => {
      ProductShippingOptions(elem, fulfillmentOptions, shippingCenter);
    });
  }
  catch(e){
    console.error("Failed to initialize Custom Product Options");
    console.error(e);
  }
}


FE.BUILDS.Theme.UpdateApplicatorLicense = async(products, licenseProperty) => {
  for(let i=0; i<products.length; i++){
    const product = products[i];
    const { key, properties } = product;

    if(properties['__valid_customer_applicator_states'] !== undefined) {
      continue;
    }

    properties['__valid_customer_applicator_states'] = licenseProperty;

    await fetch(`/cart/change.js`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'id': key,
        'properties': properties
      })
    });
  }
}