// The below list is where we ONLY sell to, please keep note of this
export default (restriction_type) => {
  const chemicalAllowedStates = [
    'Alabama',
    'Arkansas',
    'Colorado',
    'Delaware',
    'Florida',
    'Georgia',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Maryland',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Nebraska',
    'Nevada',
    'Ohio',
    'Oklahoma',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Texas',
    'Utah',
    'Wisconsin',
    'Wyoming',
  ];

  const fertilizerAllowedStates = [
    'Alabama',
    'Arizona',
    'Connecticut',
    'Indiana',
    'Iowa',
    'Kansas',
    'Louisiana',
    'Maine',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];

  const seedAllowedStates = [
    'Arizona',
    'Connecticut',
    'Florida',
    'Georgia',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'New Jersey',
    'North Carolina',
    'Ohio',
    'Oklahoma',
    'South Dakota',
    'Tennessee',
    'Texas',
    'West Virginia',
    'Wyoming',

  ];

  switch (restriction_type){
    case 'Chemical':
      return chemicalAllowedStates;
    case 'Fertilizer':
      return fertilizerAllowedStates;
    case 'Seed':
      return seedAllowedStates
    default:
      console.warn('Potential Issue here, no Product Type specified, we will not account for proper filtering!')
  }
  return [];
}