import React, { useState, useCallback } from 'react';
import { render } from 'react-dom';
import statesHash from './statesHash';
import productRestrictionAdapter from "./productRestrictionAdapter";

import styles from './App.hashed.scss';

const SHIPPING = 'Nationwide Direct Ship';
const PICKUP = 'Pickup';

const ProductShippingOptions = ({ fulfillmentOptions, shippingCenter, availableForDistribution }) => {
  const [shippingType, setShippingType] = useState(SHIPPING);
  const [fullfillmentCenter, setFullfillmentCenter] =
      useState(`${shippingCenter || fulfillmentOptions[0]?.locationData?.location_name}`);

  const handleShippingType = useCallback((e) => {
    setShippingType(e.currentTarget.value)
    if(e.currentTarget.value === SHIPPING ){
      setFullfillmentCenter(shippingCenter || fulfillmentOptions[0]?.locationData?.location_name);
      return;
    }
    setFullfillmentCenter(`${fulfillmentOptions[0]?.locationData?.location_name}`);
  }, [setShippingType, setFullfillmentCenter]);

  const handleFulfillmentChange = useCallback((e) => {
    setFullfillmentCenter(e.currentTarget.value)
  }, [setFullfillmentCenter])


  return (
    <div className={styles.ProductOption}>
      <h4>Choose Delivery Type</h4>
      <span className={styles.fe_option_value_element}>
          <span className={styles.radioContainer}>
            <input type="radio" name="shipping_method" value={SHIPPING} onChange={handleShippingType}
                   checked={shippingType === SHIPPING} />
            <label htmlFor="shipping_method">{SHIPPING}</label>
          </span>

          <span className={`${styles.radioContainer} ${!(fulfillmentOptions && fulfillmentOptions.length > 0) ? styles.disabled : ''}`}>
            <input type="radio" name="shipping_method" value={PICKUP} onChange={handleShippingType}
                   checked={shippingType === PICKUP} disabled={!(fulfillmentOptions && fulfillmentOptions.length > 0)}
            />
            <label htmlFor="shipping_method">{fulfillmentOptions && fulfillmentOptions.length > 0 ? PICKUP : `${PICKUP} (No Locations Available)`}</label>
          </span>
        {shippingType === PICKUP && (
            <div className={styles.selectContainer}>
              <select onChange={handleFulfillmentChange}>
                {fulfillmentOptions && fulfillmentOptions.length > 0 ? fulfillmentOptions.map((fOption) => {
                  return (
                      <option key={fOption?.locationData?.location_zip} disabled={!availableForDistribution[fOption?.stateName]}
                              value={`${fOption?.locationData?.location_name}`}>{`${fOption?.locationData?.location_name}${!availableForDistribution[fOption?.stateName] ? ' (Not Available for Distribution)': ''}`}</option>

                  )
                }) : <option disabled={true}>No Pickup Locations Available</option> }
                {}
              </select>
            </div>
        )}

          <input type="hidden" name="properties[Delivery Method]" value={shippingType} />

          <span>
            <input type="hidden" name="properties[Fulfillment Center]" value={fullfillmentCenter} />
          </span>
      </span>
    </div>
  );
};

export default async (elem, fulfillmentOptions, shippingCenter) => {
  const isFederallyRestricted = elem?.dataset?.isFederallyRestricted;
  const isStateRestricted = elem?.dataset?.isStateRestricted;
  const customerApplicatorStates = elem?.dataset?.validCustomerApplicatorStates?.split(',') || [];
  const allowedStatesType = elem?.dataset?.productStatesAllowedByType;
  let availableForDistribution = elem?.dataset?.availableForDistribution;
  let restrictedStates = elem?.dataset?.restrictedStates;


  // Please see this File here for where the States are put!
  const AllowedStatesArray = productRestrictionAdapter(allowedStatesType);


  if(availableForDistribution){
    availableForDistribution = JSON.parse(availableForDistribution);
  }

  if(restrictedStates){
    restrictedStates = JSON.parse(restrictedStates);
  }


  fulfillmentOptions = fulfillmentOptions.filter(f => {
    if(f.location_name === 'Nationwide Direct Ship'){
      return false;
    }

    const stateCode = f.location_name.split(', ')[1];
    const stateName = statesHash[stateCode];

    if(customerApplicatorStates.includes(stateName)){
      return true;
    }

    if(isFederallyRestricted === 'true'){
      return false;
    }

    if(isStateRestricted === 'true' && restrictedStates[stateName]){
      return false;
    }

    if(AllowedStatesArray.length !== 0 && !AllowedStatesArray.includes(stateName)){
      return false;
    }


    return true;
  }).map(m => {
    const stateCode = m.location_name.split(', ')[1];
    const stateName = statesHash[stateCode];

    return {
      locationData: {
        ...m
      },
      stateCode,
      stateName,
    }
  })


  const props = {
    fulfillmentOptions,
    shippingCenter,
    availableForDistribution,
  };
  render(<ProductShippingOptions {...props} />, elem);
};